.light {
  --fibra-benefits-background-color: var(--color-neutral-100);
  --fibra-benefits-title-color: var(--color-neutral-600);
  --fibra-benefits-card-border: var(--color-neutral-200);
  --fibra-benefits-card-background-color: var(--color-white-full);
  --fibra-benefits-card-svg-background-color: var(--color-success-150);
  --fibra-benefits-card-title-color: var(--color-neutral-600);
  --fibra-benefits-card-icon-color: var(--color-primary-500);
  --fibra-benefits-card-text-color: var(--color-neutral-500);
  --fibra-benefits--anchor-anchorcta-consult-background-color: var(--color-secondary-300);
  --fibra-benefits-anchor-whatsapp-hover-border: var(--color-primary-400);
  --fibra-benefits-anchor-whatsapp-hover-color: var(--color-primary-400);
  --fibra-benefits-anchor-whatsapp-hover-color-icon: var(--color-primary-400);
  --fibra-benefits-header-anchorcta-consult-mobile: var(--color-neutral-600);
  --fibra-benefits-header-anchorcta-consult-desktop: var(--color-primary-400);
}

.dark {
  --fibra-benefits-background-color: var(--color-black-full);
  --fibra-benefits-title-color: var(--color-neutral-100);
  --fibra-benefits-card-border: var(--color-neutral-200);
  --fibra-benefits-card-background-color: var(--color-black-full);
  --fibra-benefits-card-svg-background-color: var(--color-primary-500);
  --fibra-benefits-card-svg-color: var(--color-primary-200);
  --fibra-benefits-card-title-color: var(--color-neutral-100);
  --fibra-benefits-card-icon-color: var(--color-primary-300);
  --fibra-benefits-card-text-color: var(--color-neutral-100);
  --fibra-benefits--anchor-anchorcta-consult-background-color: var(--color-secondary-300);
  --fibra-benefits-anchor-whatsapp-hover-border: var(--color-primary-200);
  --fibra-benefits-anchor-whatsapp-hover-color: var(--color-primary-200);
  --fibra-benefits-anchor-whatsapp-hover-color-icon: var(--color-primary-200);
  --fibra-benefits-header-anchorcta-consult-mobile: var(--color-neutral-600);
  --fibra-benefits-header-anchorcta-consult-desktop: var(--color-primary-200);
}

.fibra-benefits {
  background-color: var(--fibra-benefits-background-color);

  font-family: 'Simplon BP';
  font-style: normal;
  align-self: stretch;

  padding-block: 40px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      &__header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &--tag {
          font-size: 0.75rem;
        }

        &__title {
          color: var(--fibra-benefits-title-color);

          font-size: 1.75rem;
          font-weight: 700;
          line-height: 130%;
        }
      }

      &__cta-consult {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: var(--fibra-benefits-header-anchorcta-consult-desktop);

        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        line-height: 150%;

        &:hover {
          color: var(--color-primary-500);

          svg path {
            fill: var(--color-primary-500);
          }
        }

        svg path {
          fill: var(--fibra-benefits-header-anchorcta-consult-desktop);
        }

        &__icon {
          width: 24px;
          height: 24px;
          padding: 8px;
        }
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow-x: auto;

      padding: 20px 16px;

      gap: 24px;

      &__item {
        background: var(--fibra-benefits-card-background-color);
        border: 1px solid var(--fibra-benefits-card-border);
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);

        display: flex;
        flex-direction: column;

        padding: 24px;
        gap: 12px;

        border-radius: 16px;

        &__title-box {
          color: var(--fibra-benefits-card-title-color);

          display: flex;
          align-items: center;

          gap: 12px;

          &__icon {
            background-color: var(--fibra-benefits-card-svg-background-color);

            display: flex;
            justify-content: center;
            padding: 12px;

            width: 48px;
            height: 48px;
            border-radius: 8px;
            gap: 8px;

            svg path {
              fill: var(--fibra-benefits-card-icon-color);
            }
          }

          &__title {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 120%;
          }
        }

        &__text {
          color: var(--fibra-benefits-card-text-color);

          font-size: 1rem;

          font-weight: 400;
          line-height: 150%;

          a {
            color: var(--color-primary-400);
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }

    &__cta {
      display: flex;
      flex-direction: column;

      gap: 12px;

      &__whatsapp {
        border: 1px solid var(--fibra-benefits-anchor-whatsapp-hover-border);
        color: var(--fibra-benefits-anchor-whatsapp-hover-color);

        display: flex;
        justify-content: center;
        align-items: center;

        height: 48px;
        padding: 2px 24px;
        gap: 8px;

        font-size: 1rem;
        font-weight: 500;
        line-height: 150%;

        border-radius: 999px;
        text-decoration: none;

        svg path {
          fill: var(--fibra-benefits-anchor-whatsapp-hover-color-icon);
        }

        &:hover {
          color: var(--fibra-benefits-anchor-whatsapp-hover-color);
          border: var(--fibra-benefits-anchor-whatsapp-hover-border);

          svg path {
            fill: var(--fibra-benefits-anchor-whatsapp-hover-color-icon);
          }
        }
      }

      &__consult {
        width: 100%;

        &__svg {
          fill: var(--fibra-benefits-header-anchorcta-consult-mobile);
        }
      }
    }
  }
}

.fibra-benefits-dash-button {
  display: none;
}

@media (min-width: 768px) {
  .fibra-benefits {
    &__container {
      &__content {
        &__header {
          gap: 16px;

          &__title {
            width: 587px;

            font-size: 32px;
            line-height: 125%;
            width: 587px;
          }
        }
      }

      &__cards {
        flex-direction: row;
        padding-inline: 36px;

        &__item {
          align-items: flex-start;

          flex: 0 0 calc(50% - 12px);
          box-shadow: none;
        }
      }

      &__cta {
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;

        &__consult,
        &__whatsapp {
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: 1124px) {
  .fibra-benefits {
    padding-block: 64px;

    &__container {
      position: relative;

      &__cards {
        padding-inline: 24px;
      }

      &__content {
        display: flex;

        flex-direction: row;
        justify-content: space-between;

        &__header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          &__title {
            width: 498px;
          }
        }

        &__cta-consult {
          display: none;
          width: auto;
        }
      }

      &__cards {
        position: relative;
        flex-wrap: nowrap;
        scrollbar-width: none;

        &__item {
          max-width: 312px;

          .fibra-benefits-dash-button {
            top: 670px;
          }
        }
      }

      &__cta {
        &__consult {
          display: flex;
          position: absolute;
          top: 46px;
          right: 2%;

          &_svg {
            fill: var(--fibra-benefits-header-anchorcta-consult-desktop);
          }
        }

        &__whatsapp {
          display: none;
        }
      }
    }
  }

  .fibra-benefits-dash-button {
    display: flex;
  }
}

@media (min-width: 1366px) {
  .fibra-benefits {
    &__container {
      &__content {
        &__header {
          display: flex;
          flex-direction: column;

          align-items: flex-start;
          gap: 16px;

          &__title {
            width: 498px;
          }
        }
      }

      &__cards {
        width: 1320;
        margin: 0 auto;
        padding-inline: 0;

        &__item {
          width: 312px;
        }
      }

      &__cta {
        &__consult {
          right: calc((100% - 1320px) / 2);


        }
      }
    }
  }

  .fibra-benefits-dash-button {
    display: none;
  }
}