.light{
--internal-navigation-background-color: var(--color-neutral-600);
}
.dark{
	--internal-navigation-background-color: var(--color-black-full);
}
.internal-navigation {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	background-color: var(--internal-navigation-background-color);
	width: 100%;

	a {
		text-decoration: none;
	}

	&__bar {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		overflow-x: scroll;
		scrollbar-width: none;

		&__shaft {
            pointer-events: none;
			display: none;
			align-items: center;
			width: 144px;
			padding-block: 8px;
			position: absolute;
			z-index: 3;

			&__image {
				pointer-events: all;
				user-select: none;
				width: 24px;
			}
		}

		&__shaft_left {
			left: 0;
			justify-content: flex-start;
			background: linear-gradient(90deg, var(--internal-navigation-background-color) 0%, rgba(61, 61, 61, 0) 100%);
			img {
				transform: rotate(0.5turn);
			}
		}

		&__shaft_right {
			right: 0;
			justify-content: flex-end;
			background: linear-gradient(270deg, var(--internal-navigation-background-color) 0%, rgba(61, 61, 61, 0) 100%);
		}

		&__anchor {
			font-family: 'Simplon BP';
			font-size: 1rem;
			line-height: 24px;
			color: white;
			padding-inline: 20px;
			padding-block: 8px;
			min-width: max-content;

			&:nth-child(1) {
				padding-inline: 8px 20px;
			}
			&--active {
				color: #70ff84;
				font-weight: 700;
			}
		}

	
	}
}
