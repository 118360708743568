// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/*
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-variant-ligatures: none;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

body,
input,
textarea,
button {
	font-family: 'Simplon BP', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
}

button {
	cursor: pointer;

	border: none;
	outline: 0;
	background-color: rgba(0, 0, 0, 0);
}

img {
	max-width: 100%;
}
