.light{
    --inner-banner-section-mobile-background: linear-gradient(88deg,#151a15 10.94%,rgba(29,37,29,0) 62.84%), linear-gradient(322deg,rgba(20,20,20,.4) 47.27%,rgba(0,61,0,.4) 100.37%), linear-gradient(178deg,rgba(28,36,26,0) 62.62%,#1c241a 158.98%), linear-gradient(359deg,#1c241a00 93.66%,#1c241a 57.05%), var(--color-neutral-600);
    --inner-banner-section-tablet-background: linear-gradient(87deg, #151A15 -1.3%, rgba(29, 37, 29, 0.00) 26.95%), linear-gradient(267deg, rgba(20, 20, 20, 0.40) 5.36%, rgba(0, 61, 0, 0.40) 31.57%), var(--color-neutral-600);
    
    --inner-banner-card-box-shadow: 0px 4px 37px 0px rgba(112, 255, 132, 0.34);
    --inner-banner-card-outline: 1px solid rgba(0, 0, 0, 0);
    
    
    --inner-banner-card-mobile-background: linear-gradient(12deg, #10230E 8.87%, rgba(16, 35, 14, 0.78) 30.89%, rgba(16, 35, 14, 0.00) 55.26%), linear-gradient(89deg, #0C1B0B 29.61%, rgba(0, 0, 0, 0.00) 203.33%);
    --inner-banner-card-tablet-background: var(--color-success-800);
    --inner-banner-card-paramount-background: var(--color-success-650);
    --inner-banner-card-recommendation-notebook-background: linear-gradient(261deg, #10230E 17.92%, rgba(16, 35, 14, 0.00) 72.67%),linear-gradient(168deg, rgba(13, 58, 17, 0.60) 16.18%, rgba(0, 107, 0, 0.00) 83.21%),#1C241A;

    --inner-banner-desktop-anchor-hover: var(--color-primary-500);
}
.dark{
    --inner-banner-section-mobile-background: var(--color-neutral-600);
    --inner-banner-section-tablet-background: var(--color-neutral-600);
    
    --inner-banner-card-box-shadow: none;
    --inner-banner-card-outline: 1px solid var(--color-neutral-400);

    --inner-banner-card-mobile-background: var(--color-black-full);
    --inner-banner-card-tablet-background: var(--color-black-full);
    --inner-banner-card-paramount-background: var(--color-black-full);
    --inner-banner-card-recommendation-notebook-background: var(--color-black-full);
    
    --inner-banner-desktop-anchor-hover: var(--color-primary-300);
}

.inner-banner{
    background: var(--inner-banner-section-mobile-background);

    &__main{
        display: flex;
        flex-direction: column;
        padding-block: 40px;
        gap: 80px;

        &__cards{
            display: flex;
            flex-direction: column;
            gap: 24px;
            
            &__card{
                background: var(--inner-banner-card-mobile-background);
                
                width: 100%;
                height: 200px;
                padding: 24px 32px;
                
                border-radius: 24px;
                outline: var(--inner-banner-card-outline);
                box-shadow: var(--inner-banner-card-box-shadow);
                backdrop-filter: blur(4px);
                overflow: hidden;

                text-decoration: none;
                
                &__content{
                    height: 100%;
                    position: relative;
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &__title{
                        color: var(--color-white-full);
                        font-size: 1.25rem;
                        font-weight: 700;

                        span{
                            color: var(--color-primary-200);
                        }
                    }

                    &__description{
                        color: var(--color-neutral-100);

                        font-size: 0.75rem;
                        line-height: 135%;
                        letter-spacing: 0.24px;
                    }

                    &__cta{
                        color: var(--color-primary-200);
                        
                        width: fit-content;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;
                        
                        text-decoration: none;
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }

                &__background-img{
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    right: 0;
                }
                
            }

            &__card:hover{
                outline: 1px solid var(--color-primary-200);
            }
        }
    
        &__links-container{
            display: flex;
            flex-direction: column;
            gap: 16px;
    
            &__description{
                display: flex;
                flex-direction: column;
                
                &__caption{
                    color: var(--color-white-full);
    
                    font-size: 1.25rem;
                    font-weight: 400;
                    line-height: 120%;
                }
                &__title{
                    color: var(--color-primary-200);
                    
                    max-width: 400px;
                    margin-top: 12px;
                    margin-bottom: 12px;

                    font-size: 1.75rem;
                    line-height: 130%;
                }
                &__paragraph{
                    color: var(--color-neutral-100);
                    
                    max-width: 300px;
                    font-size: 1rem;
                    line-height: 150%;
                }
            }
    
            &__mobile{
                display: flex;
                flex-direction: column;
                gap: 16px;
    
                &__anchor{
                    color: var(--color-neutral-100);
                    
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 13px;
                    gap: 18px;
                    
                    font-size: 1.125rem;
                    font-weight: 700;
                    // line-height: 120%; 

                    border: 1px solid var(--color-neutral-400);
                    border-radius: 12px;
                    text-decoration: none;

                    &__chevron{
                        margin-left: auto;
                    }
                }
            }
    
            &__desktop{
                display: none;
            }
        }
    } 
}

.inner-banner__card-paramount{
    background-color: var(--inner-banner-card-paramount-background) !important;
    
    .inner-banner__main__cards__card__content__title{
        max-width: 154px;
        max-width: 214px;
    }
    .inner-banner__main__cards__card__content__description{
        max-width: 155px;
    }
}

.inner-banner__recommendation{
    .inner-banner__main__cards__card__content__title{
        max-width: 154px;
    }
    .inner-banner__main__cards__card__content__description{
        max-width: 138px;
    }
}

@media (min-width: 768px) {
    .inner-banner{
        background: var(--inner-banner-section-tablet-background);

        &__main{    
            gap: 60px;

            &__cards{
                flex-direction: row;

                &__card{
                    background:var(--inner-banner-card-tablet-background);

                    height: 237px;
                    max-width: 648px;
                    padding: 30.5px 37px;
                }
            }
    
            &__links-container{
                flex-direction: row;
                gap: 28px;
                
                &__description{
                    justify-content: center;
                    max-width: 204px;

                    &__caption{
                        font-size: 1rem;
                        line-height: 150%;
                    }
                    &__title{
                        margin-top: 8px;
                        margin-bottom: 16px;

                        font-size: 1.25rem;
                    }
                    &__paragraph{
                        display: flex;
                        flex-direction: column;
                        max-width: 186px;
                        
                        font-size: 0.875rem;
                        line-height: 140%;
                    }
                }

                &__mobile{
                    width: 100%;
                    gap: 10px;
                    
                    &__anchor{
                        padding: 26px 40px;
                        gap: 20px;

                        font-size: 1.25rem;
                    }
                }
            }
        }
    }

    .inner-banner__card-paramount{
        .inner-banner__main__cards__card__content__title{
            max-width: 164px;
        }
        .inner-banner__main__cards__card__content__description{
            max-width: 155px;
        }
    }

    .inner-banner__recommendation{
        .inner-banner__main__cards__card__content__description{
            max-width: 156px;
        }
    }
}

@media (min-width: 1124px){
    .inner-banner{
        &__main{
            padding-block: 64px;
            
            &__cards{
                flex-direction: row;
            
                &__card{
                    height: 262px;
                    max-width: 648px;
                    padding: 44px 0 30px 32px;
            
                    &__content{
                        &__title{
                            font-size: 1.75rem;
                        }
            
                        &__description{
                            font-size: 1rem;
                        }
                    }
                }
            }

            &__links-container{
                gap: 40px;

                &__description{
                    max-width: 382px;

                    &__caption{
                        font-size: 1.25rem;
                    }

                    &__title{
                        font-size: 1.75rem;
                    }

                    &__paragraph{
                        display: block;
                        max-width: 100%;

                        font-size: 1rem;
                    }
                }

                &__mobile{
                    display: none;
                }

                &__desktop{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    
                    &__link{
                        &__title{
                            color: var(--color-neutral-100);
    
                            margin-top: 16px;
                            margin-bottom: 12px;
    
                            font-size: 1.25rem;
                        }
    
                        &__anchor{
                            background-color: var(--color-primary-200);
                            color: var(--color-neutral-600);
                            
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            padding: 8px 12px;
                            align-items: center;
    
                            font-size: 1rem;
                            font-weight: 500;
                            line-height: 150%;
    
                            text-decoration: none;
                            border-radius: 999px;
                        }

                        &__anchor:hover{
                            background-color: var(--inner-banner-desktop-anchor-hover);
                        }
                    }
                }
            }
        }
    }

    .inner-banner__card-paramount{
        .inner-banner__main__cards__card__content__title{
            max-width: 300px;
        }

        .inner-banner__main__cards__card__content__description{
            max-width: 254px;
        }
    }

    .inner-banner__recommendation{
        background: var(--inner-banner-card-recommendation-notebook-background);

        .inner-banner__main__cards__card__content__title{
            max-width: 212px;
        }

        .inner-banner__main__cards__card__content__description{
            max-width: 225px;
        }
    }
}

