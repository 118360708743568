.light {
  --modal-offers-background: #ffff;
  --modal-offers-text-color: #000;
  --modal-offers-anchor-color: #00a300;
  --modal-offers-img-color: none;
}
.dark {
  --modal-offers-background: #000;
  --modal-offers-text-color: #ffffff;
  --modal-offers-anchor-color: #00d61e;
  --modal-offers-img-color: brightness(100);
}

.modal-offers-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1041;
  max-width: 920px;
  padding: 24px;
  gap: 10px;
  background-color: var(--modal-offers-background);
  opacity: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  visibility: hidden;

  &__title-main {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__title-content {
      display: flex;
      flex-direction: column;
      &__text-details {
        color: var(--modal-offers-text-color);
        font-family: 'Simplon BP';
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 12px;
      }
      &__title-text {
        color: var(--modal-offers-text-color);
        font-family: 'simplonoi-2018';
        font-size: 1.625rem;
        font-weight: 500;
        line-height: 26px;
      }
    }
    &__button-closed {
      margin-left: 10px;
      img {
        filter: var(--modal-offers-img-color);
      }
    }
  }
  &__information {
    &__list {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      justify-content: space-between;

      &__item {
        display: flex;
        flex-direction: column;

        &__first-details {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          gap: 10px;
          color: var(--modal-offers-text-color);
          font-family: 'Simplon BP';
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 19px;
        }
        &__green-line-small {
          width: 58%;
          border-bottom: 3px solid #00d61e;
          margin-bottom: 1rem;
        }
        &__discount-info {
          color: var(--modal-offers-text-color);
          font-family: 'Simplon BP';
          font-size: 1rem;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 10px;
        }
        &__discount-explanation {
          color: var(--modal-offers-text-color);
          font-family: 'Simplon BP';
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 30px;

          &__anchor {
            font-family: 'Simplon BP';
            font-size: 1rem;
            font-weight: 700;
            line-height: 24px;
            color: var(--modal-offers-anchor-color);
            text-decoration: none;
          }
        }
        &__green-line-design {
          width: 108%;
          border-bottom: 3px solid #00d61e;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
.modal-offers-details[open] {
  width: 100%;
  transform: none;
  visibility: visible;
  opacity: 1;
}
.modal-offers-details[close] {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.modal-offers-details-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity 3s linear;
}
.modal-offers-details-overlay[open] {
  display: block;
  opacity: 0.5;
  transition: opacity 0.15s linear;
}
.modal-offers-details-overlay[close] {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .modal-offers-details {
    margin-left: auto;
    &__title-main {
      &__button-closed {
        margin-left: 242px;
      }
    }
    &__information {
      &__list {
        &__item {
          &__green-line-small {
            width: 21%;
          }
          &__green-line-design {
            width: 99%;
          }
        }
      }
    }
  }
}
@media (min-width: 1364px) {
  .modal-offers-details {
    margin-left: auto;
    &__title-main {
      &__button-closed {
        margin-left: 511px;
      }
    }
    &__information {
      &__list {
        &__item {
          &__green-line-design {
            width: 98%;
          }
        }
      }
    }
  }
}
