.light {
	--section-client-services-mobile-background-gradient: linear-gradient(
			87deg,
			var(--color-success-700) -1.3%,
			rgba(29, 37, 29, 0) 26.95%
		),
		linear-gradient(
			267deg,
			rgba(20, 20, 20, 0.4) 5.36%,
			rgba(0, 61, 0, 0.4) 31.57%
		),
		var(--color-neutral-600);
	--client-services-icon-fill: var(--color-primary-400);
}

.dark {
	--section-client-services-mobile-background-gradient: var(
		--color-neutral-600
	);
	--client-services-icon-fill: var(--color-neutral-600);
}

.section-client-services {
	background: var(--section-client-services-mobile-background-gradient);

	&__main {
		display: flex;
		flex-direction: column;
		padding-block: 40px;
		gap: 16px;

		&__desktop-holder {
			&__texts {
				color: var(--color-white-full);
				display: flex;
				flex-direction: column;

				gap: 12px;

				&__tag {
					font-size: 1.25rem;
					line-height: 24px;
				}

				&__title {
					font-size: 1.75rem;
					font-weight: 700;
					line-height: 40px;
				}

				&__description {
					color: var(--color-neutral-100);
					max-width: 270px;
					font-size: 1rem;
					line-height: 24px;
				}
			}

			&__links {
				display: none;
			}
		}

		&__mobile-holder {
			display: flex;
			flex-direction: column;
			gap: 40px;

			&__cards {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 16px;

				&__card {
					background-color: var(--color-neutral-94);
					color: var(--color-neutral-600);

					min-height: 144px;
					display: flex;
					flex-direction: column;
					gap: 16px;
					flex: 1 0 156px;
					padding: 24px;
					padding-right: 20px;

					text-decoration: none;
					h3{
					font-size: 1rem;
					font-weight: 500;
					line-height: 24px;
					}

					border-radius: 24px;
					border-color: var(--color-neutral-94);

					svg {
						fill: var(--client-services-icon-fill);
					}
				}

				&__card:hover {
					box-shadow: 0px 0px 20px 0px rgba(112, 255, 132, 0.8);
				}
			}

			&__links {
				display: flex;
				flex-direction: column;
				gap: 14px;
			}
		}
	}

	.section-client-services {
		&__link {
			color: var(--color-primary-200);

			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			text-decoration: none;
			font-size: 1rem;
			line-height: 24px;
			font-weight: 500;

			svg {
				fill: var(--color-primary-200);
			}
		}

		&__link:hover {
			color: var(--color-primary-500);

			svg {
				fill: var(--color-primary-500);
			}
		}

		&__description {
			color: var(--color-neutral-100);

			font-size: 1rem;
			line-height: 24px;
		}
	}
}

@media (min-width: 532px) {
	.section-client-services {
		&__main {
			&__mobile-holder {
				&__cards {
					&__card {
						flex: 1 0 235px;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.section-client-services {
		&__main {
			gap: 26px;

			&__desktop-holder {
				display: flex;
				flex-direction: column;
				gap: 12px;

				&__texts {
					&__title {
						line-height: 36.4px;
					}

					&__description {
						display: none;
					}
				}

				&__links {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
			}

			&__mobile-holder {
				&__cards {
					gap: 12px;

					&__card {
						min-height: 168px;
						flex-basis: 0;
					}
				}

				&__links {
					display: none;
				}
			}
		}
	}
}

@media (min-width: 1124px) {
	.section-client-services {
		&__main {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-block: 64px;

			&__mobile-holder {
				&__cards {
					gap: 24px;

					&__card {
						max-width: 176px;
					}
				}
			}

			&__desktop-holder {
				&__texts {
					&__title {
						max-width: 230px;
						font-size: 1.75rem;
					}
				}
			}
		}
	}
}

@media (min-width: 1180px) {
	.section-client-services {
		&__main {
			&__desktop-holder {
				&__texts {
					&__title {
						max-width: 100%;
					}
				}
			}
		}
	}
}
