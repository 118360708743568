.light {
  --offer-card_section-bg: var(--color-white-full);
  --offer-card_title-color: var(--color-neutral-550);
  --offer-card_subtitle-color: var(--color-neutral-600);
  --offer-card_note-text-color: var(--color-neutral-600);
  --offer-card_note-icon-color: var(--color-neutral-600);
  --offer-card_nav-bg: var(--color-neutral-200);
  --offer-card_nav-text-color: var(--color-neutral-550);
  --offer-card_nav-active-bg: var(--color-primary-500);
  --offer-card_nav-active-text: var(--color-success-150);
  --offer-card_card-border: 1px solid var(--color-neutral-200);
  --offer-card_amount-color: var(--color-primary-400);
  --offer-card_btn-bg: var(--color-secondary-300);
  --offer-card_btn-text: var(--color-neutral-600);
  --offer-card_tag-border: none;
  --offer-card_high-value-body-bg: var(--color-neutral-600);
  --offer-card_high-value-cta-bg: var(--color-warning-300);
  --offer-card_high-value-footer-bg: var(--color-neutral-500);
  --offer-card_high-value-footer-icon-bg: var(--color-neutral-600);
  --offer-card_btn-carousel-bg: var(--color-neutral-100);
  --offer-card_btn-carousel-border: 1px solid var(--color-neutral-200);
  --offer-card_button-nav-background-right: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 2.76%,
    #fff 97.46%
  );
  --offer-card_button-nav-background-left: linear-gradient(
    90deg,
    #fff 2.76%,
    rgba(255, 255, 255, 0) 97.46%
  );
  --offer-card_arrow-dots-color: brightness(0);
}

.dark {
  --offer-card_section-bg: var(--color-black-full);
  --offer-card_title-color: var(--color-neutral-100);
  --offer-card_subtitle-color: var(--color-neutral-100);
  --offer-card_note-text-color: var(--color-neutral-100);
  --offer-card_note-icon-color: var(--color-neutral-100);
  --offer-card_nav-bg: var(--color-neutral-600);
  --offer-card_nav-text-color: var(--color-primary-200);
  --offer-card_nav-active-bg: var(--color-primary-200);
  --offer-card_nav-active-text: var(--color-neutral-600);
  --offer-card_card-border: none;
  --offer-card_amount-color: var(--color-primary-600);
  --offer-card_btn-bg: var(--color-black-full);
  --offer-card_btn-text: var(--color-neutral-100);
  --offer-card_tag-border: 1px solid #f5f5f5;
  --offer-card_high-value-body-bg: var(--color-black-full);
  --offer-card_high-value-cta-bg: var(--color-secondary-200);
  --offer-card_high-value-footer-bg: var(--color-neutral-600);
  --offer-card_high-value-footer-icon-bg: var(--color-black-full);
  --offer-card_high-value-border: 1px solid #8f8f8f;
  --offer-card_btn-carousel-bg: var(--color-primary-200);
  --offer-card_btn-carousel-border: none;
  --offer-card_btn-carousel-icon-color: var(--color-neutral-600);
  --offer-card_button-nav-background-right: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 2.76%,
    #000 97.46%
  );
  --offer-card_button-nav-background-left: linear-gradient(
    90deg,
    #000 2.76%,
    rgba(255, 255, 255, 0) 97.46%
  );
  --offer-card_arrow-dots-color: none;
}

.offers-cards {
  background-color: var(--offer-card_section-bg);

  border-radius: 8px;
  padding-block: 40px;

  display: flex;
  flex-direction: column;

  &__cards {
    &--gradient-mask {
      display: none;
    }
  }
}

.offers-cards__header {
  &--title {
    color: var(--offer-card_title-color);

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 133.333%;

    margin-bottom: 20px;
    max-width: 580px;
  }

  &--subtitle {
    color: var(--offer-card_subtitle-color);

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;

    max-width: 372px;
  }

  &--note {
    display: flex;
    align-items: center;

    gap: 10px;
    margin-top: 16px;

    &--text {
      color: var(--offer-card_note-text-color);

      font-size: 0.875rem;
    }

    svg {
      width: 24px;
      height: 18px;

      path {
        fill: var(--offer-card_note-icon-color);
      }
    }
  }
}

.offers-cards__nav {
  display: flex;
  flex-wrap: wrap;

  gap: 10px;
  margin-block: 26px;

  &--item {
    background-color: var(--offer-card_nav-bg);
    color: var(--offer-card_nav-text-color);

    padding: 8px 12px;
    border-radius: 8px;

    font-size: 0.75rem;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: 0.015rem;

    &:hover {
      outline: 1px solid var(--color-neutral-400);
    }

    &[data-nav-state='active'] {
      background-color: var(--offer-card_nav-active-bg);
      color: var(--offer-card_nav-active-text);
    }
  }
}

.offers-cards__cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.offers-cards__cards-container {
  display: flex;
  flex-direction: column;

  gap: 2px;
  overflow: scroll;
  width: 100%;
  margin-top: -10px;

  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  cursor: grab;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.offers-cards__tag-special {
  display: flex;
  justify-content: center;
  text-align: center;

  &--content {
    background-color: var(--color-black-full);
    color: var(--color-secondary-300);
    outline: var(--offer-card_tag-border);
    position: relative;
    z-index: 1;

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.0175rem;

    padding: 10px 16px;
    border-radius: 999px;
    width: 93%;
  }
}

.offers-cards__countdown {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-primary-400);
  color: var(--color-white-full);

  gap: 4px;
  border-radius: 999px;
  padding: 3.5px 10px;

  font-size: 0.625rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.0175rem;

  position: absolute;
  top: 24px;
  right: 24px;
}

.offers-cards__card {
  padding-block: 10px 14px;
  opacity: 1;

  &__wrapper {
    background-color: var(--color-white-full);
    outline: var(--offer-card_card-border);

    border-radius: 24px;

    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.14);
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 24px;

    &-header {
      display: flex;
      flex-direction: column;
      min-height: 72px;

      &--title {
        color: var(--color-neutral-400);

        font-size: 0.875rem;
        font-weight: 500;
        line-height: 150%;
      }

      &--speed {
        display: flex;
        gap: 6px;

        &-before {
          color: var(--color-neutral-300);

          font-size: 0.625rem;
          font-weight: 500;
          text-decoration: line-through;
          line-height: 130%;
        }

        color: var(--color-neutral-600);

        font-size: 1.5rem;
        font-weight: 700;
        line-height: 130%;

        &-icon-up {
          margin-top: 8px;
        }
      }

      &--subtitle {
        color: var(--color-neutral-400);

        font-size: 0.75rem;
        line-height: 135%;
        letter-spacing: 0.24px;

        display: flex;
        align-items: center;
        img{
          margin: 0 4px;
        }
      }
    }

    &-price {
      display: flex;
      flex-direction: column;
      margin-bottom: 14px;

      &--amount {
        &-before {
          color: var(--color-neutral-400);

          font-size: 0.75rem;
          font-weight: 400;
          line-height: 135%;

          margin-top: 14px;

          b {
            font-weight: 400;
            text-decoration: line-through;
          }
        }

        color: var(--offer-card_amount-color);

        font-size: 2rem;
        font-weight: 700;
        line-height: 125%;
      }

      &--per-month {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 140%;
        margin-left: 2px;
      }
    }

    &-credit {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--color-neutral-400);

      font-size: 0.75rem;
      margin-bottom: 14px;
    }

    &-cta {
      background-color: var(--offer-card_btn-bg);
      color: var(--offer-card_btn-text);

      min-width: 100%;
      margin-bottom: 14px;
    }

    &-details {
      color: var(--color-neutral-300);

      font-size: 0.75rem;
      font-weight: 500;
      line-height: 135%;
      letter-spacing: 0.015rem;

      text-align: center;
    }

    &-install-tag {
      background-color: var(--color-health-100);
			color: var(--color-health-600);
      border-radius: 4px;
      width: fit-content;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 135%; 
      letter-spacing: 0.24px;
			display: flex;
			align-items: center;
			padding: 0 2px 0 2px;
			margin-bottom: 14px;
			gap: 4px;
			b{
				color: var(--color-health-400);
				font-weight: 500;
			}
    }
  }
}

.offers-cards__card__footer {
  background-color: var(--color-neutral-100);

  padding: 24px;
  border-radius: 0 0 24px 24px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--describe {
      display: flex;
      align-items: center;
      gap: 8px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 35px;
        min-height: 35px;
        border-radius: 6px;

        outline: 1px solid var(--funcional-neutral-neutral-light, #e0e0e0);
      }

      &-title {
        color: var(--color-neutral-500);

        display: flex;
        flex-direction: column;

        font-size: 0.75rem;
        font-weight: 500;
        line-height: 135%;
        letter-spacing: 0.24px;
      }

      &-subtitle {
        color: var(--color-neutral-400);

        display: flex;
        align-items: center;
        gap: 3px;

        font-size: 0.625rem;
        letter-spacing: 0.0125rem;

        &-colored {
          color: var(--color-primary-400);
        }
      }
    }

    &--attach {
      display: flex;
      gap: 8px;

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        gap: 6px;

        span {
          color: var(--color-neutral-400);

          font-size: 0.625rem;
          font-weight: 500;
          line-height: 135%;
          letter-spacing: 0.0125rem;
        }
      }
    }
  }
}

.offers-cards__card__footer--streamings-modal {
  display: none;
  margin-left: 20px;
  position: relative;
  z-index: 10;

  &-content {
    background-color: var(--color-neutral-200);

    padding: 8px 12px;
    border-radius: 8px;

    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
    width: fit-content;
    margin-top: -28px;

    &--icon-close {
      position: absolute;
      top: -14px;
      right: -14px;
    }
  }

  canvas {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 12px solid var(--color-neutral-200);

    position: absolute;
    bottom: 0;
    right: 0;
    left: 97px;

    margin-bottom: 68px;
    width: 10px;
    height: 12px;
  }
}

.offers-cards__card__footer--streamings-modal[open] {
  display: flex;
}

.offers-cards__card__footer-item--count {
  background: var(--color-white-full);

  width: 40%;
  max-width: 136px;
  height: 40px;
  border-radius: 999px;
  outline: 1px solid var(--color-neutral-200);

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding-inline: 12px;
    display: flex;
    svg path {
      fill: var(--color-primary-400);
    }
  }
  button:disabled {
    svg path {
      fill: var(--color-neutral-200);
    }
  }

  &-number {
    font-size: 0.875rem;

    color: var(--color-neutral-600);
  }
}

.offers-cards__hight-value {

  .offers-cards__card {
    &__wrapper {
      outline: var(--offer-card_high-value-border);
      background-color: var(--offer-card_high-value-body-bg);
    }

    &__body {
      &-header {
        &--title {
          color: var(--color-neutral-100);
        }

        &--speed {
          color: var(--color-primary-200);
        }

        &--subtitle {
          color: var(--color-neutral-100);
         

        }

        &--direct-link {
          display: flex;
          align-items: center;
          gap: 6px;
          height: 27px;

          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 135%;
          letter-spacing: 0.24px;

          text-decoration: none;
          color: var(--color-primary-200);
        }
      }

      &-price {
        color: var(--color-primary-200);

        &--amount {
          color: var(--color-primary-200);
        }
      }

      &-credit {
        color: var(--color-white-full);
      }

      &-cta {
        background-color: var(--offer-card_high-value-cta-bg);
        color: var(--color-neutral-600);

        &:hover {
          background-color: var(--color-warning-400);
        }
      }
    }
  }

  .offers-cards__card__footer {
    background-color: var(--offer-card_high-value-footer-bg);

    &-item {
      &--describe {
        &-title {
          color: var(--color-neutral-100);
        }

        &-subtitle {
          color: var(--color-neutral-300);
          &-colored {
            color: var(--color-primary-200);
          }
        }

        &-icon {
          background-color: var(--offer-card_high-value-footer-icon-bg);

          outline: none;

          img {
            filter: brightness(100);
          }
        }
      }

      &--attach {
        &-item {
          background-color: var(--offer-card_high-value-footer-bg);

          span {
            color: var(--color-neutral-200);
          }
        }
      }
    }
  }
}

.offers-cards__carousel-dots {
  &__container {
    display: none;
  }
}
.offers-cards__icon-bright{
  filter: invert(100%) brightness(100%);
}

@media (min-width: 768px) {
  .offers-cards {
    padding-block: 38px;

    &__cards {
      position: relative;

      &--gradient-mask {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        pointer-events: none; /* Permite interações através do elemento */

        &-left {
          position: absolute;
          visibility: hidden;
          width: 10%;
          height: 100%;
          left: 0;
          background: var(--offer-card_button-nav-background-left);
          z-index: 10;
        }
        &-right {
          position: absolute;

          width: 10%;
          height: 100%;
          right: 0;
          background: var(--offer-card_button-nav-background-right);
          z-index: 10;
        }
      }
    }

    &__card {
      padding-inline: 13px;
      &__wrapper {
        min-width: 310px;
      }
      &--firstOnTab {
        padding-left: 36px;
      }
      &--lastOnTab {
        padding-right: 36px;
      }
    }
  }
  .offers-cards__header {
    &--title {
      font-size: 1.75rem;
      line-height: 130%;
    }

    &--subtitle {
      font-size: 1rem;
      line-height: 150%;
      
    }

    &--note {
      display: none;
    }
  }
  .offers-cards__nav {
    margin-block: 20px 30px;
  }

  .offers-cards__hight-value {
    .offers-cards__card {
      &__wrapper {
        max-width: 310px;
      }
    }
  }

  .offers-cards__cards-container {
    flex-direction: row;

    gap: 0;
    overflow: scroll;
    // padding: 0 36px;
  }

  .offers-cards__carousel-dots {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__arrow-prev,
    &__arrow-next {
      filter: var(--offer-card_arrow-dots-color);
    }
    &__arrow-prev {
      rotate: 180deg;
    }
    justify-content: center;
  }


  .offers-cards__card__footer--streamings-modal {
    &-content--icon-close {
      display: none;
    }
    canvas {
      position: absolute;
      bottom: 70px;
      left: 90px;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1124px) {
  .offers-cards {
    &__wrapper {
      max-width: 304px;
    }
  }
  .offers-cards__card__footer-item--describe-subtitle:hover
    .offers-cards__card__footer--streamings-modal {
    display: flex;
  }
}

#toggle-theme {
  img {
    display: none;
  }
}

@media (min-width: 1364px) {
  .offers-cards {
    &__cards {
      width: fit-content;
    }
    &__card {
      &:first-of-type {
        padding-left: 13px;
      }

      &:last-of-type {
        padding-right: 13px;
      }
    }

    &__cards-container {
      width: fit-content;

      user-select: auto;
      -webkit-user-drag: auto;
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      cursor: default;
    }
  }
}
@media (min-width: 1464px) {
  .offers-cards {
    &__cards {
      &--gradient-mask {
        display: none;
      }
      // width: 1320px;
    }
  }
}

[data-card-context='globoplay'] {
  display: none;
}

[data-card-context='alto-valor'] {
  display: none;
}
