.light {
  --blog-background-color: var(--color-neutral-100);

  --blog-texts-main-color: var(--color-neutral-600);
  --blog-texts-span-color: var(--color-primary-500);
  --blog-texts-anchor-color-and-fill: var(--color-primary-400);

  --blog-card-tag-smart-house-background: var(--color-smarthouse-300);
  --blog-card-tag-tech-background: var(--color-information-400);
  --blog-card-tag-tech-color: var(--color-white-full);
  --blog-card-tag-internet-background: var(--color-entretainment-300);
  --blog-card-btn-background-color: var(--color-primary-600);

  --blog-card-anchor-background-color: var(--color-primary-500);

  --filtro: none;
}

.dark {
  --blog-background-color: var(--color-black-full);

  --blog-texts-main-color: var(--color-neutral-100);
  --blog-texts-span-color: var(--color-primary-200);
  --blog-texts-anchor-color-and-fill: var(--color-primary-200);

  --blog-card-tag-smart-house-background: var(--color-white-full);
  --blog-card-tag-tech-background: var(--color-white-full);
  --blog-card-tag-tech-color: var(--color-black-full);
  --blog-card-tag-internet-background: var(--color-white-full);
  --blog-card-btn-background-color: var(--color-neutral-500);

  --blog-card-anchor-background-color: var(--color-black-full);

  --filtro: grayscale(100%);
}

.blog-oi {
  background-color: var(--blog-background-color);

  &__main {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-block: 40px;

    &__texts {
      color: var(--blog-texts-main-color);

      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title-anchor {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
          max-width: 168px;

          font-size: 28px;
          font-weight: 700;
          line-height: 130%;

          span {
            color: var(--blog-texts-span-color);
          }
        }

        &__anchor {
          color: var(--blog-texts-anchor-color-and-fill);

          display: flex;
          flex-direction: row;
          gap: 8px;

          text-decoration: none;
          font-size: 1rem;
          font-weight: 500;
          line-height: 150%;

          svg {
            fill: var(--blog-texts-anchor-color-and-fill);
          }

          &:hover{
            color: var(--color-primary-500);
            svg {
             fill:  var(--color-primary-500);
            }
          }
        }
      }

      &__description {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
    }

    &__carousel-box {
      display: flex;
      gap: 16px;
      // width: fit-content;

      overflow-x: scroll;
      scrollbar-width: none;

      &__card {
        background-color: var(--color-white-full);

        width: 100%;
        min-width: 242px;
        height: fit-content;

        overflow: hidden;
        border-radius: 32px;

        &__story-cover {
          min-height: 135px;
          padding: 24px;

          position: relative;
          z-index: 1;

          &__image {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            filter: var(--filtro);
            height: 100%;
            object-fit: cover;
            object-position: top;
          }

          &__tag {
            min-width: 67px;
            height: 25px;
            padding: 2px 8px 2px 8px;
            position: sticky;
            z-index: 2;

            font-size: 14px;
            font-weight: 700;
            line-height: 140%;

            border-radius: 8px;
          }
        }

        &__story-resume {
          background-color: var(--color-white-full);

          min-width: 229px;
          min-height: 227px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: sticky;
          z-index: 2;
          padding: 12px;
          padding-bottom: 24px;

          &__title {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
          }

          &__resume {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
          }

          &__btn {
            background-color: var(--blog-card-anchor-background-color);
            color: var(--color-white-full);

            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 8px;

            font-size: 1rem;
            font-weight: 500;
            line-height: 150%;

            border-radius: 999px;
            text-decoration: none;
          }

          &__btn:hover {
            cursor: pointer;
            background-color: var(--blog-card-btn-background-color);
          }
        }
      }
    }
  }
}

.tag {
  &__smart-house {
    background-color: var(--blog-card-tag-smart-house-background);
  }

  &__tech {
    color: var(--blog-card-tag-tech-color);
    background-color: var(--blog-card-tag-tech-background);
  }

  &__internet {
    background-color: var(--blog-card-tag-internet-background);
  }
}

@media (min-width: 768px) {
  .blog-oi {
    &__main {
      padding-block: 64px;
      &__texts {
        &__title-anchor {
          &__title {
            max-width: fit-content;
          }
        }
      }

      &__carousel-box {
        gap: 24px;

        &__card {
          min-width: 336px;

          &__story-cover {
            min-height: 256px;

            &__image {
              min-width: 526px;
              right: -140px;
            }
          }

          &__story-resume {
            min-height: 256px;
            padding: 24px;

            &__title {
              font-size: 20px;
              line-height: 120%;
            }

            &__resume {
              font-size: 16px;
              line-height: 150%;
            }

            &__btn {
              max-width: fit-content;
              padding: 12px 24px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1124px) {
  .blog-oi {
    &__main {
      &__texts {
        &__description {
          font-size: 20px;
          line-height: 120%;
        }
      }

      &__carousel-box {
        &__card {
          min-width: 424px;
          max-width: 424px;

          &__story-cover {
            min-height: 174px;

            &__image {
              right: 0;
            }
          }

          &__story-resume {
            min-height: 239px;
            padding: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: 1364px) {
  .blog-oi {
    &__main {
      &__texts {
        &__title-anchor {
          &__title {
            font-size: 32px;
            line-height: 125%;
          }
        }

        &__description {
          font-size: 20px;
          line-height: 120%;
        }
      }
    }
  }
}
