.light {
    --notices-carousel-section-background-color: var(--color-neutral-100);
    --notices-carousel-anchor-color-mobile: var(--color-white-full);
    --notices-carousel-anchor-color-tablet: var(--color-primary-400);
    --notices-carousel-anchor-background-color-mobile: var(--color-primary-400);
    --notices-carousel-anchor-background-color-mobile-hover: var(--color-primary-500);
    --notices-carousel-anchor-background-color-tablet: var(--color-neutral-100);
    --notices-carousel-anchor-background-color-tablet-hover: var(--color-primary-500);
    --notices-carousel-anchor-svg-fill-mobile: var(--color-white-full);
    --notices-carousel-anchor-svg-fill-tablet: var(--color-primary-400);
    --notices-carousel-header-color: var(--color-black-full);
    --notices-carousel-tag-background-color: var(--color-success-100);
    --notices-carousel-tag-color: var(--color-success-400);
    --notices-carousel-content-cards-color: var(--color-neutral-100);
    --notices-carousel-content-cards-image-border-color: none;
    --notices-carousel-content-cards-image-filter: saturate(1);
    --notices-carousel-dash-button-active-background-color: var(--color-neutral-600);
    --notices-carousel-arrow-nav-tablet-background-color: var(--color-white-full);
    --notices-carousel-section-border-top: none;
}

.dark {
    --notices-carousel-section-background-color: var(--color-black-full);
    --notices-carousel-anchor-color-mobile: var(--color-neutral-600);
    --notices-carousel-anchor-color-tablet: var(--color-primary-200);
    --notices-carousel-anchor-background-color-mobile: var(--color-primary-300);
    --notices-carousel-anchor-background-color-mobile-hover: var(--color-primary-400);
    --notices-carousel-anchor-background-color-tablet: var(--color-black-full);
    --notices-carousel-anchor-background-color-tablet-hover: var(--color-primary-400);
    --notices-carousel-anchor-svg-fill-mobile: var(--color-neutral-600);
    --notices-carousel-anchor-svg-fill-tablet: var(--color-primary-200);
    --notices-carousel-header-color: var(--color-neutral-100);
    --notices-carousel-tag-background-color: var(--color-success-500);
    --notices-carousel-tag-color: var(--color-success-200);
    --notices-carousel-content-cards-color: var(--color-neutral-100);
    --notices-carousel-content-cards-image-border-color: 1px solid var(--color-neutral-400);
    --notices-carousel-content-cards-image-filter: saturate(0);
    --notices-carousel-dash-button-active-background-color: var(--color-neutral-100);
    --notices-carousel-arrow-nav-tablet-background-color: var(--color-primary-200);
    --notices-carousel-section-border-top: 1px solid var(--color-neutral-300);
}


.notices-carousel {
    padding-block: 40px;
    background-color: var(--notices-carousel-section-background-color);

    &__main-content {
        display: flex;
        flex-direction: column;
        gap: 32px;

        &__anchor {
            color: var(--notices-carousel-anchor-color-mobile);
            background-color: var(--notices-carousel-anchor-background-color-mobile);

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            font-weight: 500;
            text-decoration: none;
            line-height: 150%;

            width: 91%;
            height: 48px;
            border-radius: 50px;

            &__svg {
                fill: var(--notices-carousel-anchor-svg-fill-mobile);
            }
        }

        &__anchor:hover {
            background-color: var(--notices-carousel-anchor-background-color-mobile-hover);
        }

        &__header {
            color: var(--notices-carousel-header-color);

            &__tag {
                margin-bottom: 32px;
            }

            &__title {
                display: flex;
                flex-direction: row;

                margin-bottom: 20px;

                font-size: 1.75rem;
                font-weight: 700;
                line-height: 130%;
            }

            &__subtitle {
                font-size: 1rem;
                font-weight: 400;
                line-height: 150%;
            }
        }

        &__cards {
            display: flex;
            flex-direction: row;
            gap: 16px;

            overflow-y: scroll;
            scroll-behavior: smooth;
            scrollbar-width: none;

            &__card {
                position: relative;
                display: flex;
                justify-content: center;

                min-width: 247px;
                min-height: 318px;

                &__content {
                    color: var(--notices-carousel-content-cards-color);
                    position: absolute;
                    bottom: 44px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    text-align: center;

                    &__title {
                        font-weight: 500;
                        line-height: 150%;
                        max-width: 222px;
                    }

                    &__subtitle {
                        font-size: 0.75rem;
                        line-height: 135%;
                        letter-spacing: 0.24px;
                        max-width: 202px;
                    }
                }

                &__image {
                    border-radius: 32px;
                    border: var(--notices-carousel-content-cards-image-border-color);
                    filter: var(--notices-carousel-content-cards-image-filter);
                    width: 247px;
                    height: 318px;
                }
            }
        }
    }

    &__arrow-nav-tablet--next,
    &__arrow-nav-tablet--prev {
        display: none;
    }
}



@media (min-width: 768px) {
    .notices-carousel {
        padding-block: 60px;
        border-top: var(--notices-carousel-section-border-top);

        &__main-content {
            position: relative;

            gap: 0px;
            display: flex;
            flex-direction: row;

            &__anchor {
                position: absolute;
                top: 348px;

                width: 224px;
                margin-left: 36px;
            }

            &__header {
                margin-top: 80px;
                padding-right: 10px;
                max-width: 350px;

                &__tag {
                    margin-bottom: 16px;
                }

                &__title {
                    margin-bottom: 14px;
                    font-size: 2rem;
                    min-width: 306px;
                }

                &__subtitle {
                    min-width: 302px;
                }
            }

            &__cards {
                padding-left: 0px;
                gap: 24px;

                &__card {
                    min-width: 312px;
                    min-height: 416px;

                    &__content {
                        &__title {
                            font-size: 1.25rem;
                            line-height: 120%;
                            max-width: 280px;
                        }

                        &__subtitle {
                            font-size: 0.875rem;
                            line-height: 140%;
                            max-width: 248px;

                        }
                    }

                    &__image {
                        width: 312px;
                        height: 416px;
                    }
                }
            }
        }

        &__arrow-nav-tablet--next,
        &__arrow-nav-tablet--prev {
            background-color: var(--notices-carousel-arrow-nav-tablet-background-color);
            border: 1px solid var(--color-neutral-200);

            position: absolute;
            height: 64px;
            width: 64px;

            padding: 20px;


            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            top: 34%;

            border-radius: 999px;

        }

        &__arrow-nav-tablet--next {
            right: 2%;
        }

        &__arrow-nav-tablet--prev {
            display: none;
            left: 362px;

            img {
                rotate: 180deg;
            }
        }

    }

    .notices-carousel-dash-button {
        position: absolute;
        top: 442px;
        left: 350px;
    }
}

@media (min-width: 1124px) {
    .notices-carousel {
        padding-block: 64px;

        &__main-content {
            display: flex;
            flex-direction: column;
            gap: 45px;

            &__anchor {
                color: var(--notices-carousel-anchor-color-tablet);
                background-color: var(--notices-carousel-anchor-background-color-tablet);

                display: flex;
                justify-content: flex-end;

                top: 78px;
                right: 2%;
                margin-left: 0px;
                padding: 0px;

                &__svg {
                    fill: var(--notices-carousel-anchor-svg-fill-tablet);
                }
            }

            &__anchor:hover {
                color: var(--notices-carousel-anchor-background-color-tablet-hover);
                background-color: var(--notices-carousel-anchor-background-color-tablet);

            }

            &__anchor:hover svg {
                fill: var(--notices-carousel-anchor-background-color-tablet-hover);
            }

            &__header {
                margin-top: 0px;
                max-width: none;

                &__title {
                    margin-bottom: 12px;
                    max-width: 398px;
                    font-size: 1.75rem;
                }

                &__subtitle {
                    max-width: 388px;
                }
            }

            &__cards {
                padding-left: 24px;
            }
        }

        &__arrow-nav-tablet--next,
        &__arrow-nav-tablet--prev {
            display: none;
        }
    }

    .notices-carousel-dash-button {
        position: static;
    }
}

@media (min-width: 1366px) {
    .notices-carousel {

        &__main-content {

            &__header {
                &__title {
                    font-size: 2rem;
                }
            }

            &__cards {
                padding-left: 0px;
            }

            &__anchor {
                right: calc((100% - 1320px) / 2);
            }
        }
    }
}