.light {
    --faq-tittle: var(--color-neutral-600);
    --faq-anchor: var(--color-primary-400);
    --faq-box-text: var(--color-neutral-400);
    --faq-chevron: none;
    --faq-carousel-dash-button-active-background-color: var(--color-neutral-600);
}

.dark {
    --faq-tittle: var(--color-neutral-100);
    --faq-anchor: var(--color-primary-200);
    --faq-box-text: var(--color-neutral-100);
    --faq-chevron: brightness(100);
    --faq-carousel-dash-button-active-background-color: var(--color-neutral-100);
}

.faq {
    display: flex;
    flex-direction: column;

    padding-top: 40px;

    gap: 46px;
    &__tittle {
        display: flex;
        flex-direction: column;

        width: 312px;

        gap: 24px;

        &__text {
            color: var(--faq-tittle);

            max-width: 300px;

            font-size: 1.75rem;
            font-weight: 700;
            line-height: 36.4px;
        }

        &__question-icon {
            fill: var(--faq-anchor);
        }
    }

    &__container {
        display: flex;
        flex-direction: column;

        padding-bottom: 40px;
        gap: 40px;

        details {
            transition: max-height 0.4s ease-in;
            overflow: hidden;
            max-height: 200px;
        }

        details summary::-webkit-details-marker {
            display: none;
        }

        details[open] {
            max-height: 600px;
            summary {
                img {
                    transition: 0.3s;
                    transform: rotate(180deg);
                }
            }
        }

       

        details[open] button {
            display: block;
        }

        summary {
            cursor: pointer;
            img {
                transition: transform 0.3s;
            }
        }

        &__main {
            display: flex;
            flex-direction: column;

            gap: 24px;

            &__item {
                display: flex;
                flex-direction: column;

                gap: 24px;

                border-bottom: 1px solid #dbdbdb;

                &__question {
                    display: flex;

                    padding-bottom: 24px;

                    align-items: center;
                    justify-content: space-between;
                    &__tittle {
                        color: var(--faq-tittle);

                        min-width: 272px;

                        font-size: 1.25rem;
                        font-weight: 400;
                        line-height: 32px;
                    }
                }

                &__answer {
                    display: flex;
                    flex-direction: column;

                    padding-bottom: 24px;
                    gap: 10px;
                    p {
                        color: var(--faq-box-text);

                        padding-block: 5px;

                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 24px;

                        &__span {
                            color: var(--faq-anchor);
                        }
                    }
                }

                &__content {
                    display: flex;

                    gap: 26px;

                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;

                    overflow-y: scroll;
                    scroll-behavior: smooth;
                    scrollbar-width: none;

                    &__colluns {
                        display: flex;
                        flex-direction: column;

                        gap: 5px;
                        align-items: flex-start;

                        min-width: 186px;

                        button {
                            color: var(--faq-anchor);

                            display: flex;
                            align-items: start;

                            text-decoration: none;
                        }

                        .btn_anchor_faq:hover {
                            color: #006B00;
                        }
                    }
                }
                
                &__dots {
                    margin-left: 0px;
                    margin-inline: 0px;
                    padding-top: 20px;
                }
            }
        }
        &__button {
            color: var(--faq-anchor);
    
            display: none;
            flex-direction: row;
            justify-content: center;
            align-items: center;
    
            border-radius: 999px;
            border: 1px solid var(--faq-anchor);
    
            width: 100%;
            height: 48px;
            gap: 8px;
    
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;

            &__question-icon {
                fill: var(--faq-anchor);
                rotate: 180deg;
            }
        }

        .faq__container__button:hover{
            border: 2px solid var(--faq-anchor);
        }
    }

    &__arrow {
        filter: var(--faq-chevron);

        rotate: 180deg;
    }
}

@media (min-width: 768px) {
    .faq {
        gap: 40px;
        padding-top: 80px;

        &__tittle {
            width: 332px;


            font-size: 1.5rem;

            &__text {
                min-width: 336px;

                font-size: 2rem;
                line-height: 40px;
            }
        }

        &__container {
            padding-bottom: 80px;
            
            &__main {
                &__question {
                    &__tittle {
                        width: 676px;
                    }
                }

                &__content {
                    width: fit-content;
                }
            }

            &__button {
                width: 250px;
    
                font-size: 1rem;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}

@media (min-width: 1124px) {
    .faq {
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;

        &__tittle {
            min-width: 292px;

            &__text {
                min-width: 310px;

                font-size: 1.75rem;
                line-height: 36.4px;
            }
        }

        &__container {
            align-items: flex-end;

            width: 100%;
            max-width: 872px;

            &__main {
                width: 100%;

                &__item {
                    &__answer {
                        p {
                            width: 706px;
                        }
                    }
                }
            }
        }
    }
}
