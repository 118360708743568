:root {
	--color-black-full: #000;
	--color-white-full: #fff;

	/* 	PRIMARY */
	--color-primary-100: #c2ffca;
	--color-primary-150: #9cfca7;
	--color-primary-200: #70ff84;
	--color-primary-300: #00d61e;
	--color-primary-400: #00a300;
	--color-primary-410: #009e0c;
	--color-primary-500: #006b00;
	--color-primary-600: #003d00;
	--color-secondary-100: #fffac2;
	--color-secondary-200: #fff470;
	--color-secondary-300: #fbd84b;
	--color-secondary-400: #c9ad3d;
	--color-secondary-500: #7c6728;
	--color-secondary-600: #3e3414;

	/* 	FUNCTIONAL */
	--color-neutral-90: #f9f9f9;
	--color-neutral-94: #f1f1f1;
	--color-neutral-95: #f2f2f2;
	--color-neutral-100: #f5f5f5;
	--color-neutral-200: #e0e0e0;
	--color-neutral-225: #dbdbdb;
	--color-neutral-230: #d3d3d3;
	--color-neutral-250: #cccccc;
	--color-neutral-300: #b8b8b8;
	--color-neutral-399: #909090;
	--color-neutral-400: #8f8f8f;
	--color-neutral-450: #595959;
	--color-neutral-500: #3d3d3d;
	--color-neutral-550: #303030;
	--color-neutral-580: #282828;
	--color-neutral-600: #1f1f1f;
	--color-neutral-625: #161616;
	--color-neutral-630: #141414;
	--color-neutral-650: #121212;
	--color-neutral-655: #0e0e0e;
	--color-success-100: #d9ece3;
	--color-success-150: #c2ffca;
	--color-success-200: #72d489;
	--color-success-300: #34ad51;
	--color-success-400: #24803a;
	--color-success-500: #1b602b;
	--color-success-600: #0e2f16;
	--color-success-650: #10230D;
	--color-success-700: #11230E;
	--color-success-800: #0C1A0A;
	--color-warning-100: #fffac2;
	--color-warning-200: #fff470;
	--color-warning-300: #fbd84b;
	--color-warning-400: #c9ad3d;
	--color-warning-500: #7c6728;
	--color-warning-600: #3e3414;
	--color-error-100: #fbdcda;
	--color-error-200: #f5a8a3;
	--color-error-300: #ea3f34;
	--color-error-400: #b91e13;
	--color-error-500: #6f120c;
	--color-error-600: #370906;
	--color-information-100: #cce8f5;
	--color-information-200: #87c9e8;
	--color-information-300: #65bae1;
	--color-information-400: #2899cd;
	--color-information-500: #144c66;
	--color-information-600: #0a2633;

	/* 	VERTICAL BRANDS */
	--color-smarthouse-100: #dbf9e7;
	--color-smarthouse-200: #a5f0c4;
	--color-smarthouse-300: #4be188;
	--color-smarthouse-400: #3db46d;
	--color-smarthouse-500: #2d8752;
	--color-smarthouse-600: #143d25;
	--color-entretainment-100: #edf9d0;
	--color-entretainment-200: #d9f490;
	--color-entretainment-300: #a5e114;
	--color-entretainment-400: #8ac111;
	--color-entretainment-500: #6f9b05;
	--color-entretainment-600: #2a3b02;
	--color-finance-100: #deefd1;
	--color-finance-200: #b1da95;
	--color-finance-300: #6fb63f;
	--color-finance-400: #4a792a;
	--color-finance-500: #2e4c1a;
	--color-finance-600: #1c2d10;
	--color-education-100: #cceee8;
	--color-education-200: #80d5c7;
	--color-education-300: #2cbfa3;
	--color-education-400: #04a082;
	--color-education-500: #006755;
	--color-education-600: #003d32;
	--color-health-100: #ffeadb;
	--color-health-200: #ffcaa6;
	--color-health-300: #ff954d;
	--color-health-400: #e27227;
	--color-health-500: #ad4d0b;
	--color-health-600: #4d2205;
	--color-energy-100: #fff1d0;
	--color-energy-200: #fedc89;
	--color-energy-300: #fdb913;
	--color-energy-400: #f4a608;
	--color-energy-500: #a26402;
	--color-energy-600: #513201;

	/* 	CHARTS */
	--color-pink-100: #ffe5fb;
	--color-pink-200: #ffc2f6;
	--color-pink-300: #e165d5;
	--color-pink-400: #cd27bc;
	--color-pink-500: #891a7d;
	--color-pink-600: #440d3f;
	--color-purple-100: #e7dcf9;
	--color-purple-200: #c3a8f0;
	--color-purple-300: #9363e3;
	--color-purple-400: #6525d0;
	--color-purple-500: #43198b;
	--color-purple-600: #220c45;

	/* 	Outros */
}
